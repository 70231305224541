import React from "react"
import SEO from "../components/common/seo";
import BlogFeed from "../components/news/blog-feed";
import {graphql} from "gatsby";
import LayoutSecondary from "../components/common/layout-secondary";

const NewsPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    let title = lang === 'es' ? `Noticias - Tecalis` : 'News - Tecalis';
    let metaDescription = lang === 'es' ? "Consulta las últimas noticias sobre Tecalis y mantenerte informado de las novedades del universo digital." : "Check the latest news about Tecalis and stay informed of the news of the digital universe.";

    if (pageContext.currentPage > 1) {
        title = lang === 'es' ? `Noticias - Página ${pageContext.currentPage} - Tecalis` : `News - Page ${pageContext.currentPage} - Tecalis`;
        metaDescription += lang === 'es' ? ` Página ${pageContext.currentPage}.` : ` Page ${pageContext.currentPage}.`;
    }

    return (
        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={title} description={metaDescription}/>
            <BlogFeed data={data} pageContext={pageContext}/>
        </LayoutSecondary>
    )
}

export default NewsPage

export const newsPageQuery = graphql`
   query postsQuery($limit: Int!, $skip: Int!, $lang: String!, $dateFormat: String!){
        allStrapiPost(
            sort: { fields: [publish_date], order: [DESC] }
            limit: $limit
            skip: $skip
            filter: {
                locale: { eq: $lang }
            }){
            edges {
                node {
                    id
                    media {
                        name
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 720, base64Width: 720, maxHeight: 440) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    lead
                    title
                    description
                    resume
                    content                  
                    category {
                        title
                    }
                    publish_date(formatString: $dateFormat, locale: $lang)
                    fields{
                        slug
                    }
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
    }
    `
