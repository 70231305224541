import React from "react"
import {Link} from "gatsby";

const BlogPost = ({blogPost}) => {

    return (
        <div className="col-12 col-xl-4 d-flex">

            <div className="card border-card mb-6">
                <Link className="card-img-top" to={blogPost.fields.slug}>
                    {/** todo configuar url en config o .env.development*/}
                    { blogPost.media &&
                    <img src={blogPost.media.localFile.childImageSharp.fluid.src} alt={blogPost.media.name}
                         className="img-fluid img-top-border"/>
                    }
                </Link>

                <Link className="card-body" to={blogPost.fields.slug}>
                    {/** Heading */}
                    <h3 className="font-weight-bold spacing">
                        {blogPost.title}
                    </h3>
                    {/** Text */}
                    <p className="mb-0 text-muted spacing">
                        {blogPost.description}
                    </p>
                </Link>

                <div className="card-meta">

                    <hr className="card-meta-divider"/>

                    {/** Badge */}
                    {blogPost.category && <span className="badge badge-pill badge-tecalis">
                        <span className="h6 spacing">{blogPost.category.title}</span>
                    </span>
                    }

                    <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                        <time dateTime={blogPost.publish_date}>{blogPost.publish_date}</time>
                    </p>
                </div>

            </div>
        </div>
    )
}

export default BlogPost
