import React from "react"
import BlogPost from "./blog-post";
// import BlogPostfixed from "./blog-post-fixed";
import Pagination from "../common/pagination";
// import CategoriesSidebar from "./categories-sidebar";

const BlogFeed = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;

    return (
        <section className="bg-white pt-8 pb-9 pt-md-11 pb-md-13">
            <div className="container">

                {/** Heading */}
                <h1 className="display-4 font-weight-bold spacing title-feed">
                    {lang === 'es' ? 'Noticias' : 'News'}
                    <br/><br/>
                </h1>

                <div className="row">
                    {/** News */}
                    <div className="col-12 col-xl-12 d-flex">

                        <div className="row">

                            {data.allStrapiPost.edges.map((document, i) => {
                                return <BlogPost blogPost={document.node} key={i}/>
                            })}

                            <Pagination pageContext={pageContext}/>

                        </div>
                        {/** /. row */}

                    </div>
                    {/** /. News */}

                    {/** Sidebar */}
                    {/*<CategoriesSidebar/>*/}
                    {/** /. Sidebar */}

                </div>
                {/** /. row */}

            </div>
            {/** /. container */}
        </section>

    )
}

export default BlogFeed
